export const NavigationBar = () => {
  return (
    <div
      className="navbar-container"
      style={{ position: "absolute", bottom: 0, zIndex: 9999 }}
    >
      <StartButton />
      <NavbarDivider />
      <NewButton />
    </div>
  );
};

export const StartButton = () => {
  const handleClick = () => {
    console.log("Start button clicked");
  };

  return (
    <button className="start-button-wrapper" onClick={handleClick}>
      <div className="start-button"></div>
    </button>
  );
};

// Divider Component
export const NavbarDivider = () => {
  return <div className="navbar-divider" />;
};

// New Button Component
export const NewButton = () => {
  const handleClick = () => {
    console.log("New button clicked");
  };

  return (
    <button className="new-button-wrapper" onClick={handleClick}>
      <div className="camera-button"></div>
      <div className="button-text">Test</div>
    </button>
  );
};
