// Filename - App.js

import "./App.css";
import { NavigationBar } from "./components/navbar.js";
import { DraggableWindow } from "./components/windowContainer.js";

function App() {
  return (
    <div className="background-container">
      <DraggableWindow />
      <NavigationBar />
    </div>
  );
}

export default App;
